<template>
  <line-item
    :class="{'line-item--active': activeItems.find(el => (el === item.id)), 'line-item--pending-delete': pendingDelete && pendingDelete.find(el => (el === item.id))}"
    :dark="true"
    :id="item.id"
  >
    <div
      class="row no-gutters align-items-center justify-content-between height-xs--9 folder__item"
      :class="{'folder__item--editing': editMode, 'folder__item--editing': !item.onClick}"
      @click="item.onClick ? item.onClick(item) : true"
    >
      <div :class="['folder__item-status', item.status]" :title="resolveItemTooltip(item.status)"></div>

      <div
        class="folder__item-handle col-1"
        :class="{'folder__item-handle--active': orderable && editMode}"
        @click="handleDraggerClick"
      >
        <div v-if="orderable && editMode" class="icon icon--handle"></div>
      </div>

      <div
        v-if="item.onDeleteClick"
        class="folder__item-remove icon icon--remove"
        @click="item.onDeleteClick(item)"
      ></div>

      <div class="col folder__item__title">
        <div v-if="struct && struct.title">{{ struct.title.value(item) }}</div>
      </div>

      <div class="col-6" v-if="struct">
        <div class="row no-gutters align-items-center">
          <div class="data-col col" v-for="(prop, key) in struct" v-if="key !== 'title'" :key="key">
            <template v-if="typeof prop === 'string'">{{ item[key] }}</template>
            <template v-else>{{ prop.value(item) }}</template>
          </div>
        </div>
      </div>
    </div>
  </line-item>
</template>

<script>
import { mapState } from "vuex";
import lineItem from "../line_item.vue";

export default {
    name: "ExplorerItem",
    props: {
        item: {
            type: Object,
            required: true
        },
        activeItems: Array,
        pendingDelete: Array,
        struct: Object,
        editMode: Boolean,
        orderable: {
            type: Boolean,
            default: false
        }
    },
    computed: mapState({
        editableItems: state => state.editableItems
    }),
    methods: {
        handleDraggerClick(e) {
            if (this.editMode) {
                e.preventDefault();
                e.stopPropagation();
            }
        },

        resolveItemTooltip(status) {
            if (status === "disabled") {
                return "Disabled";
            }

            return "Active";
        }
    },
    components: {
        lineItem
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

.folder__item {
  font-size: fz(sm);
  cursor: pointer;

  &--editing,
  &--no-action {
    cursor: default;
  }

  &-status {
    width: 10px;
    margin-left: 10px;
    border-radius: 50%;

    &.disabled,
    &.active {
      height: 10px;
    }

    &.disabled {
      background-color: $error_red;
    }

    &.active {
      background-color: $brand_color;
    }
  }

  &-remove {
    cursor: pointer;
    margin-right: 10px;
  }

  &-handle {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    &--active {
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }
  }
}

.line-item {
  &--pending-delete {
    color: $grey_mid_1;
    .folder__item__title {
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-24px, -50%);
        background-image: url("~img/disabled.svg");
        background-repeat: no-repeat;
        background-size: 12px 12px;
        background-position: center;
        width: 12px;
        height: 12px;
      }
    }
  }
}

.data-col {
  @include below(sm) {
    font-size: 10px;
  }
}
</style>
