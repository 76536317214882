var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "iexplorer row no-gutters",
      class: { dragging: _vm.dragging },
    },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.struct && _vm.structHeader
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "row no-gutters height-xs--8 align-items-center",
                  },
                  [
                    _c("div", { staticClass: "col-6 offset-6" }, [
                      _c(
                        "div",
                        { staticClass: "row no-gutters" },
                        _vm._l(_vm.struct, function (prop, key) {
                          return typeof prop === "string" || prop.display
                            ? _c(
                                "div",
                                { key: key, staticClass: "col text--capped" },
                                [
                                  typeof prop === "string"
                                    ? [_vm._v(" " + _vm._s(prop) + " ")]
                                    : [
                                        _vm._v(
                                          " " + _vm._s(prop.display) + " "
                                        ),
                                      ],
                                ],
                                2
                              )
                            : _vm._e()
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
              ]
            : _vm._e(),
          _vm.list
            ? [
                _c(
                  "draggable",
                  {
                    attrs: {
                      list: _vm.list,
                      options: {
                        animation: 150,
                        group: "root",
                        chosenClass: "sortable-chosen",
                        handle: ".folder__sort",
                        filter: ".folder__children",
                        preventOnFilter: false,
                      },
                    },
                    on: { start: _vm.handleDragStart, end: _vm.handleDragEnd },
                  },
                  [
                    _vm._l(_vm.list, function (child) {
                      return [
                        _c(
                          "div",
                          { key: child.id },
                          [
                            child.children
                              ? [
                                  _c("folder", {
                                    attrs: {
                                      mode: _vm.mode,
                                      item: child,
                                      activeItems: _vm.activeItems,
                                      pendingDelete: _vm.pendingDelete,
                                      editMode: _vm.editMode,
                                      struct: _vm.struct,
                                      orderable: _vm.orderable,
                                    },
                                    on: {
                                      dragStart: function ($event) {
                                        return _vm.$emit(
                                          "childDragStart",
                                          arguments[0]
                                        )
                                      },
                                      dragEnd: function ($event) {
                                        return _vm.$emit(
                                          "childDragEnd",
                                          arguments[0]
                                        )
                                      },
                                    },
                                  }),
                                ]
                              : [
                                  _c("item", {
                                    attrs: {
                                      mode: _vm.mode,
                                      activeItems: _vm.activeItems,
                                      pendingDelete: _vm.pendingDelete,
                                      editMode: _vm.editMode,
                                      item: child,
                                      struct: _vm.struct,
                                      orderable: _vm.orderable,
                                    },
                                  }),
                                ],
                          ],
                          2
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }