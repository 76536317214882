<template>
    <div
        class="iexplorer row no-gutters"
        :class="{dragging: dragging}"
    >
        <div class="col-12">
            <template v-if="struct && structHeader">
                <div class="row no-gutters height-xs--8 align-items-center">
                    <div class="col-6 offset-6">
                        <div class="row no-gutters">
                            <div
                                class="col text--capped"
                                v-for="(prop, key) in struct"
                                :key="key"
                                v-if="(typeof prop === 'string') || prop.display"
                            >
                                <template v-if="typeof prop === 'string'">
                                    {{ prop }}
                                </template>
                                <template v-else>
                                    {{ prop.display }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="list">
                <draggable
                    :list="list"
                    :options="{
                        animation: 150,
                        group: 'root',
                        chosenClass: 'sortable-chosen',
                        handle: '.folder__sort',
                        filter: '.folder__children',
                        preventOnFilter: false,
                    }"
                    @start="handleDragStart"
                    @end="handleDragEnd"
                >
                    <template v-for="child in list">
                        <div :key="child.id">
                            <template v-if="child.children">
                                <folder
                                    :mode="mode"
                                    :item="child"
                                    :activeItems="activeItems"
                                    :pendingDelete="pendingDelete"
                                    :editMode="editMode"
                                    :struct="struct"
                                    :orderable="orderable"
                                    @dragStart="$emit('childDragStart', arguments[0])"
                                    @dragEnd="$emit('childDragEnd', arguments[0])"
                                />
                            </template>

                            <template v-else>
                                <item
                                    :mode="mode"
                                    :activeItems="activeItems"
                                    :pendingDelete="pendingDelete"
                                    :editMode="editMode"
                                    :item="child"
                                    :struct="struct"
                                    :orderable="orderable"
                                />
                            </template>
                        </div>
                    </template>
                </draggable>
            </template>
        </div>
    </div>
</template>

<script>
import LineItem from '../line_item.vue'
import Folder from './iexplorer_folder.vue'
import Item from './iexplorer_item.vue'
import Draggable from 'vuedraggable'

export default {
    name: 'Explorer',
    props: {
        folderDisplayProp: String,
        struct: Object,
        structHeader: Boolean,
        children: Array,
        orderable: {
            type: Boolean,
            default: false,
        },
        activeItems: {
            type: Array,
            default: () => [],
        },
        pendingDelete: {
            type: Array,
            default: () => [],
        },
        mode: {
            type: String,
            default: () => 'normal',
        },
        editMode: Boolean,
    },
    data: () => ({
        list: [],
        dragging: false,
    }),
    methods: {
        handleDragStart(e) {
            this.$emit('dragStart', e)

            this.dragging = true
        },

        handleDragEnd(e) {
            this.$emit('dragEnd', e)

            this.dragging = false
        },
    },
    components: {
        LineItem,
        Folder,
        Item,
        Draggable,
    },
    watch: {
        children(val) {
            this.list = val
        },
    },
    created() {
        this.list = this.children
    },
}
</script>

<style lang="scss">
@import '~scss/variables';
@import '~scss/mixins';

.iexplorer {
    background-color: $grey_light_2;

    &.dragging {
        .folder__children {
            display: none;
        }
    }
}

.sortable-chosen {
    .folder__children {
        display: none;
    }
}
</style>
