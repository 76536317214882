<template>
    <div
    class="folder"
    :class="{ 'folder--active': active, 'folder--edit': editMode }"
    >
        <line-item class="height-xs--7">
            <div class="folder__line row no-gutters height-xs--inherit align-items-center" @click="toggle">
                <div class="folder__head col">
                    <div v-if='isSubcategory' class="folder__icon" :class="{'folder__icon--active': !active}"></div>
                    <div v-else class="folder__icon" :class="{'folder__icon--active': !active}"></div>
                    <div class="folder__title">{{ item.title }}</div>

                    <div
                        v-if="orderable && editMode"
                        class="folder__sort icon icon--handle folder__action"
                        @click.stop.prevent
                    ></div>

                    <div
                    v-if="editMode && item.onClick"
                    class="folder__edit folder__action"
                    @click.stop="item.onClick(item)"
                    ></div>

                    <div
                    v-if="editMode && item.onDelete"
                    class="folder__delete folder__action"
                    @click.stop="item.onDelete(item)"
                    ></div>

                    <div
                        v-if='editMode && item.onAddSubcategory'
                        class="folder__add_subcategory folder__action"
                        @click.stop='item.onAddSubcategory(item)'
                    >
                    </div>
                </div>

                <div class="folder__struct col" v-if="active && struct && hasNonFolderChildren && !isSubcategory">
                    <div class="row no-gutters">
                        <template v-for="(prop, key) in struct">
                            <div class="col text--capped" v-if="typeof prop === 'string' || prop.display" :key="key">
                                <template v-if="typeof prop === 'string'">
                                    {{ prop }}
                                </template>
                                <template v-else>
                                    {{ prop.display }}
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </line-item>

        <div
            v-if="item.children"
            class="folder__children row no-gutters"
            ref="children"
            :style="{maxHeight: active ? height : 0}"
        >
            <draggable
                class="folder__children--inner col-12"
                :list="item.children"
                :options="{
                    handle: ['.folder__item-handle--active', '.folder__sort'],
                    ghostClass: 'folder__child--ghost',
                    chosenClass: 'folder__child--chosen',
                    animation: 150,
                    group: 'groups',
                    preventOnFilter: false,
                }"
                @add="calcHeight('55px'); handleDragEnd()"
                @start="handleDragStart"
                @end="handleDragEnd"
            >
                <div class="folder__child row no-gutters mt-0" v-for="(child, key) in item.children" :key="key">
                    <div class="col-12">
                        <template v-if="child.children">
                            <div class="row no-gutters">
                                <div class="block-xs--sm-left col-12">
                                    <folder
                                        :mode="mode"
                                        :item="child"
                                        :activeItems="activeItems"
                                        :pendingDelete="pendingDelete"
                                        :editMode="editMode"
                                        :struct="struct"
                                        :toggled="calcHeight"
                                        :orderable="orderable"
                                        is-subcategory
                                    />
                                </div>
                            </div>
                        </template>

                        <template v-else>
                            <item
                                :mode="mode"
                                :item="child"
                                :editMode="editMode"
                                :activeItems="activeItems"
                                :pendingDelete="pendingDelete"
                                :struct="struct"
                                :orderable="orderable"
                            />
                        </template>
                    </div>
                </div>
            </draggable>
        </div>
    </div>
</template>

<script>
import bus from '../../store/action_bus'
import lineItem from '../line_item.vue'
import item from './iexplorer_item.vue'
import toggle from '../cleverly/Toggle.vue'
import draggable from 'vuedraggable'

export default {
    name: 'Folder',
    props: {
        toggled: Function,
        item: {
            type: Object,
            required: true,
        },
        activeItems: Array,
        pendingDelete: Array,
        editMode: Boolean,
        struct: Object,
        mode: String,
        onDragStart: Function,
        onDragEnd: Function,
        orderable: {
            type: Boolean,
            default: false,
        },
        isSubcategory: Boolean
    },
    data() {
        return {
            active: bus.expandedFolders.includes(this.item.id),
            selectedItems: [],
            height: 0,
            subcategoryModal: {
                isVisible: false,
                name: null,
                id: null,
            }
        }
    },
    computed: {
        itemCount() {
            return Object.keys(this.items).length
        },
        editModeActive() {
            return bus.editModeActive
        },
        hasNonFolderChildren() {
            for (var child of this.item.children) {
                if (child.children === undefined || child.children.length == 0) {
                    return true
                }
            }

            return false
        }
    },
    methods: {
        handleDragStart() {
            this.$emit('dragStart', this.item)
        },
        handleDragEnd() {
            this.$emit('dragEnd', this.item)
        },
        toggle() {
            this.active = !this.active

            bus.$emit('toggleFolder', this.item.id)

            if (this.toggled) {
                this.toggled(this.height)
            }
        },
        calcHeight(extraHeight) {
            let height = this.$el.querySelector('.folder__children--inner').scrollHeight - 1

            if (extraHeight && typeof extraHeight === 'string') {
                height += parseInt(extraHeight, 10)
            }

            this.height = `${height}px`
        },
    },
    components: {
        lineItem,
        item,
        toggle,
        draggable,
    },
    created() {
        bus.$on('expandAllItems', () => {
            bus.$emit('expandFolder', this.item.id)

            this.active = true
        })

        bus.$on('collapseAllItems', () => {
            this.active = false
        })
    },
    mounted() {
        this.calcHeight()
    },
}
</script>

<style lang="scss">
@import '~scss/variables';
@import '~scss/mixins';

.folder {
    &__line {
        background-color: #fff;
        cursor: pointer;
    }

    &__action {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-top: 4px;
        margin-left: spacing(xs);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px;
    }

    &__sort {
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }

    &__edit {
        background-image: url('~img/edit_logo.svg');
    }

    &__delete {
        background-image: url('~img/delete.svg');
        background-size: 15px;
    }

    &__add_subcategory {
        background-image: url('~img/add_new_logo.svg');
        background-size: 15px;
    }

    &__head {
        display: flex;
        font-size: fz(sm);
    }

    &__icon {
        @include background-image('', 'folder_closed_logo.svg', '../../img/');
        background-size: contain;
        width: spacing(sm);
        height: spacing(sm);
        margin-top: 2px;
        margin-left: spacing(sm);

        &--active {
            @include background-image('', 'folder_open_logo.svg', '../../img/');
        }
    }

    &__title {
        margin-left: spacing(xs);
    }

    &__children {
        transition: max-height 100ms;
        background-color: #fff;
        overflow: hidden;

        &--inner {
            min-height: 10px;
        }
    }

    &__child {
        transition: $transition_short;

        &--ghost {
            border-left: 5px solid $brand_color;
        }
    }

    &--active {
        .folder__title {
            @include below(xs) {
                max-width: 115px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    &--edit {
        @include below(sm) {
            .folder__struct {
                display: none;
            }
        }
    }
}
</style>
