var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "folder",
      class: { "folder--active": _vm.active, "folder--edit": _vm.editMode },
    },
    [
      _c("line-item", { staticClass: "height-xs--7" }, [
        _c(
          "div",
          {
            staticClass:
              "folder__line row no-gutters height-xs--inherit align-items-center",
            on: { click: _vm.toggle },
          },
          [
            _c("div", { staticClass: "folder__head col" }, [
              _vm.isSubcategory
                ? _c("div", {
                    staticClass: "folder__icon",
                    class: { "folder__icon--active": !_vm.active },
                  })
                : _c("div", {
                    staticClass: "folder__icon",
                    class: { "folder__icon--active": !_vm.active },
                  }),
              _c("div", { staticClass: "folder__title" }, [
                _vm._v(_vm._s(_vm.item.title)),
              ]),
              _vm.orderable && _vm.editMode
                ? _c("div", {
                    staticClass:
                      "folder__sort icon icon--handle folder__action",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                      },
                    },
                  })
                : _vm._e(),
              _vm.editMode && _vm.item.onClick
                ? _c("div", {
                    staticClass: "folder__edit folder__action",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.item.onClick(_vm.item)
                      },
                    },
                  })
                : _vm._e(),
              _vm.editMode && _vm.item.onDelete
                ? _c("div", {
                    staticClass: "folder__delete folder__action",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.item.onDelete(_vm.item)
                      },
                    },
                  })
                : _vm._e(),
              _vm.editMode && _vm.item.onAddSubcategory
                ? _c("div", {
                    staticClass: "folder__add_subcategory folder__action",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.item.onAddSubcategory(_vm.item)
                      },
                    },
                  })
                : _vm._e(),
            ]),
            _vm.active &&
            _vm.struct &&
            _vm.hasNonFolderChildren &&
            !_vm.isSubcategory
              ? _c("div", { staticClass: "folder__struct col" }, [
                  _c(
                    "div",
                    { staticClass: "row no-gutters" },
                    [
                      _vm._l(_vm.struct, function (prop, key) {
                        return [
                          typeof prop === "string" || prop.display
                            ? _c(
                                "div",
                                { key: key, staticClass: "col text--capped" },
                                [
                                  typeof prop === "string"
                                    ? [_vm._v(" " + _vm._s(prop) + " ")]
                                    : [
                                        _vm._v(
                                          " " + _vm._s(prop.display) + " "
                                        ),
                                      ],
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _vm.item.children
        ? _c(
            "div",
            {
              ref: "children",
              staticClass: "folder__children row no-gutters",
              style: { maxHeight: _vm.active ? _vm.height : 0 },
            },
            [
              _c(
                "draggable",
                {
                  staticClass: "folder__children--inner col-12",
                  attrs: {
                    list: _vm.item.children,
                    options: {
                      handle: [".folder__item-handle--active", ".folder__sort"],
                      ghostClass: "folder__child--ghost",
                      chosenClass: "folder__child--chosen",
                      animation: 150,
                      group: "groups",
                      preventOnFilter: false,
                    },
                  },
                  on: {
                    add: function ($event) {
                      _vm.calcHeight("55px")
                      _vm.handleDragEnd()
                    },
                    start: _vm.handleDragStart,
                    end: _vm.handleDragEnd,
                  },
                },
                _vm._l(_vm.item.children, function (child, key) {
                  return _c(
                    "div",
                    {
                      key: key,
                      staticClass: "folder__child row no-gutters mt-0",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          child.children
                            ? [
                                _c("div", { staticClass: "row no-gutters" }, [
                                  _c(
                                    "div",
                                    { staticClass: "block-xs--sm-left col-12" },
                                    [
                                      _c("folder", {
                                        attrs: {
                                          mode: _vm.mode,
                                          item: child,
                                          activeItems: _vm.activeItems,
                                          pendingDelete: _vm.pendingDelete,
                                          editMode: _vm.editMode,
                                          struct: _vm.struct,
                                          toggled: _vm.calcHeight,
                                          orderable: _vm.orderable,
                                          "is-subcategory": "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            : [
                                _c("item", {
                                  attrs: {
                                    mode: _vm.mode,
                                    item: child,
                                    editMode: _vm.editMode,
                                    activeItems: _vm.activeItems,
                                    pendingDelete: _vm.pendingDelete,
                                    struct: _vm.struct,
                                    orderable: _vm.orderable,
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }